import React from 'react';

function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" fillRule="nonzero" transform="translate(-474 -2602)">
          <g transform="translate(423 2257)">
            <g transform="translate(0 338)">
              <path d="M59.25 22L72 9.24914619 69.75 7 59.25 17.4996986 53.25 11.4955988 51 13.7494475z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckIcon;
