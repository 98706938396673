/* global Stripe */

import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { Text, Spinner, Box } from '@chakra-ui/core';
import {
  stripeCreateSession, fetchTeam, fetchPlans, cancelAllSubscriptions,
} from '../../api';
import { stripePublishableKey, redirectUrl } from '../../environment';
import { useAuth } from '../../context/auth';

import Button from '../../components/Button';
import Card from '../../components/Card';
import PlansCard, { heightCard } from '../../components/PlansCard';

function SubscriptionCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [teamData, setTeamData] = useState(undefined);
  const [plansData, setPlansData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { authToken } = useAuth();

  // Update the user data to latest
  const updateUser = useCallback(() => {
    fetchTeam({ token: authToken })
      .then((resp) => resp.json())
      .then((data) => {
        setTeamData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error) {
          setIsLoading(false);
          setErrorMessage('Something went wrong getting your user data...');
        }
      });
  }, [authToken]);

  // Update the available plans latest
  const updatePlans = useCallback(() => {
    fetchPlans()
      .then((resp) => resp.json())
      .then((data) => {
        setPlansData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error) {
          setIsLoading(false);
          setErrorMessage('Something went wrong fetching the plans...');
        }
      });
  }, []);

  useEffect(() => {
    updateUser();
    updatePlans();
  }, [updateUser, updatePlans]);

  // Redirect to Stripe to start the payment processing
  const redirectToStripe = (sessionId) => {
    const stripe = Stripe(stripePublishableKey);

    stripe
      .redirectToCheckout({
        sessionId,
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error.message);
          setIsLoading(false);
        }
      });
  };
  // Requesting a Stripe session from the API
  const requestSubscription = (planId) => {
    setIsLoading(true);

    stripeCreateSession({
      planId,
      successUrl: redirectUrl,
      cancelUrl: redirectUrl,
      token: authToken,
    })
      .then((resp) => resp.json())
      .then((data) => {
        redirectToStripe(data.session_id);
      })
      .catch((error) => {
        if (error) {
          setIsLoading(false);
          setErrorMessage('Requesting subscription info failed. Get in touch with support.');
        }
      });
  };

  // Cancel all current subscriptions
  const cancelSubscription = () => {
    setIsLoading(true);

    cancelAllSubscriptions({
      token: authToken,
    }).then((resp) => {
      if (resp.status === 200) {
        updateUser();
      } else {
        setIsLoading(false);
        setErrorMessage('Cancelling your subscription failed. Get in touch with support.');
      }
    });
  };

  if (errorMessage) {
    return (
      <Card minHeight={heightCard}>
        <Text mb="6" mt="4" fontSize="md" maxWidth={250} mx="auto" textAlign="center">
          {errorMessage}
        </Text>
      </Card>
    );
  }

  if (isLoading || teamData === undefined || plansData === undefined) {
    return (
      <Card minHeight={heightCard}>
        <Spinner isLoading />
      </Card>
    );
  }

  const { subscription } = teamData;

  if (subscription.is_waived) {
    return (
      <Card minHeight={heightCard}>
        <Text fontSize="lg" fontFamily="heading" textAlign="center" color="white">
          You have a gifted subscription
        </Text>

        <Text mb="6" mt="4" fontSize="md" maxWidth={200} mx="auto" textAlign="center" color="white">
          Enjoy your free subscription!
        </Text>
      </Card>
    );
  }

  if (subscription.is_canceled) {
    const currentPeriodEnd = moment.unix(subscription.current_period_end).format('LL');

    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Text fontSize="lg" fontFamily="heading" textAlign="center" color="white">
          Your subscription is canceled
        </Text>

        <Text mb="6" mt="4" fontSize="md" maxWidth={200} mx="auto" textAlign="center" color="white">
          Your plan will end on
          {' '}
          {currentPeriodEnd}
        </Text>
      </Card>
    );
  }

  if (subscription.active && subscription.current_period_end) {
    const currentPeriodEnd = moment.unix(subscription.current_period_end).format('LL');

    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Text textAlign="center">
          <Text fontSize="lg" fontFamily="heading" color="white">
            Thank you for subscribing
          </Text>

          <Text mb="6" mt="4" fontSize="md" maxWidth={250} mx="auto" color="white">
            Your subscription will renew on
            {' '}
            {currentPeriodEnd}
          </Text>

          <Button small onClick={cancelSubscription}>
            Cancel subscription
          </Button>
        </Text>
      </Card>
    );
  }

  return (
    <>
      <PlansCard onChange={(stripeId) => setSelectedPlan(stripeId)}>
        <Box mt="6">
          <Button
            onClick={() => {
              requestSubscription(selectedPlan);
            }}
            width="100%"
            small
          >
            Start subscription
          </Button>
        </Box>
        <Text mt="4" fontSize="xs" mx="auto" textAlign="center">
          {subscription.active_trial && "You're currently on a 30 day trial"}
        </Text>
      </PlansCard>
    </>
  );
}

export default SubscriptionCard;
