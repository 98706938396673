import React from 'react';
import { Box, Flex } from '@chakra-ui/core';

import Wrapper from '../../components/Wrapper';

import SubscriptionCard from './SubscriptionCard';

const Subscription = () => (
  <Box px="4" pb="12">
    <Wrapper>
      <Flex justifyContent="center" alignItems="center" flexDirection="column" py={6} mt="6">
        <Box width={381}>
          <SubscriptionCard />
        </Box>
      </Flex>
    </Wrapper>
  </Box>
);

export default Subscription;
