import { theme } from '@chakra-ui/core';

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: 'Cabin, sans-serif',
    body: 'Source Sans Pro, sans-serif',
  },
  colors: {
    ...theme.colors,
    brand: {
      beige: '#F2ECE8',
      yellow: '#F7BE3C',
      blue: '#66D7D6',
      pink: '#FFB6A1',
    },
    gray: {
      50: '#e7f5fb',
      100: '#c9dde8',
      200: '#a9c6d7',
      300: '#87aec6',
      400: '#6697b5',
      500: '#4e7d9c',
      600: '#3c627a',
      700: '#2a4657',
      800: '#172a36',
      900: '#020f16',
    },
  },
};

export default customTheme;
