import React from 'react';
import { Text, Box } from '@chakra-ui/core';

import Page from '../../components/Page';
import Wrapper from '../../components/Wrapper';
import Button from '../../components/Button';

function Privacy() {
  const Paragraph = ({ children }) => (
    <Text
      pb={4}
      fontSize="lg"
      textAlign="center"
      maxWidth={480}
      color="gray.800"
      marginLeft="auto"
      marginRight="auto"
      as="p"
    >
      {children}
    </Text>
  );
  return (
    <Page
      metaData={{
        title: 'Success',
      }}
    >
      <Box position="fixed" right="8" top="8">
        <a href="mailto:support@leaderboardapp.com">
          <Button>Contact Us</Button>
        </a>
      </Box>
      <Wrapper px={4} pb="32" pt="32">
        <Text
          pb="8"
          pt="24"
          fontSize="4xl"
          fontFamily="heading"
          marginLeft="auto"
          color="gray.800"
          textAlign="center"
          marginRight="auto"
          as="h1"
        >
          You’re all set!
        </Text>
        <Paragraph>
          Now go use the /report, /report2vs2, /who-next, /leaderboard, /settings or /revert
          commands.
        </Paragraph>
      </Wrapper>
    </Page>
  );
}

export default Privacy;
