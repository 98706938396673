import React from 'react';
import { Box, Flex } from '@chakra-ui/core';

import Logo from '../../components/Logo';
import Wrapper from '../../components/Wrapper';

const Header = () => (
  <Box px={4}>
    <Wrapper>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box mt="4" color="brand.beige">
          <Logo />
        </Box>
      </Flex>
    </Wrapper>
  </Box>
);

export default Header;
