import React from 'react';
import Image from '../Image';

import logo from './logo.png';
import logo2x from './logo2x.png';

function Logo() {
  return (
    <Image src={logo} srcSet={`${logo} 1x,${logo2x} 2x`} width={[80, 80, 80, 160]} height="auto" />
  );
}

export default Logo;
