import styled from 'styled-components';

import { Box } from '@chakra-ui/core';

const Wrapper = styled(Box)`
  max-width: 1145px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export default Wrapper;
