import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Text, Flex, Spinner, Select,
} from '@chakra-ui/core';
import { fetchPlans } from '../../api';
import { amountToReadable } from '../../utils/stripe';

import Card from '../Card';

import CheckIcon from './CheckIcon';

export const heightCard = [516, 516, 516, 516];

function PlansCard({ children, onChange }) {
  const [plansData, setPlansData] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const updatePlans = useCallback(() => {
    fetchPlans()
      .then((resp) => resp.json())
      .then((data) => {
        setPlansData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error) {
          setErrorMessage('Something went wrong fetching the plans...');
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    updatePlans();
  }, [updatePlans]);

  useEffect(() => {
    if (onChange && plansData) {
      onChange(plansData[selectedPlan].stripe_plan_id);
    }
  }, [plansData, selectedPlan, onChange]);

  if (errorMessage) {
    return (
      <Card minHeight={heightCard}>
        <Text textAlign="center">{errorMessage}</Text>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card minHeight={heightCard}>
        <Spinner />
      </Card>
    );
  }

  const selectedPlanItem = plansData[selectedPlan];
  const selectedMaxUsers = selectedPlanItem.max_users;
  const maxUsersCopy = `Up to ${selectedMaxUsers} people`;

  return (
    <Card minHeight={heightCard}>
      <Box flex={1} px="12" pt="12" pb="12">
        <Text fontFamily="heading" fontSize="2xl" color="white">
          Number of employees
        </Text>
        <Select
          size="md"
          my="4"
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
        >
          {plansData.map((number, index) => (
            <option key={number.stripe_plan_id} value={index}>
              Up to
              {' '}
              {number.max_users}
            </option>
          ))}
        </Select>
        <Box mt="5">
          <ListItem>Unlimited Matches</ListItem>
          <ListItem>{maxUsersCopy}</ListItem>
          <ListItem>Turn GIF’s off</ListItem>
          <ListItem>Penalize users for not playing</ListItem>
        </Box>
        <Flex justifyContent="flex-start" alignItems="flex-end" flexDirection="row">
          <Text fontFamily="body" fontSize={100} color="white" lineHeight="none" mt="2">
            {amountToReadable(selectedPlanItem.amount)}
          </Text>
          <Text fontSize="lg" fontFamily="heading" color="white">
            /month
          </Text>
        </Flex>
        {children}
      </Box>
      <Box bg="brand.yellow" width="100%">
        <Text fontSize="sm" textAlign="center" fontWeight="medium" py={3}>
          Subscriptions can be canceled at any time
        </Text>
      </Box>
    </Card>
  );
}

function ListItem({ children }) {
  return (
    <Flex alignItems="center" py={2} color="white">
      <CheckIcon />
      <Text ml="3" fontSize="lg" color="white">
        {children}
      </Text>
    </Flex>
  );
}

export default PlansCard;
