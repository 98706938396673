import React from 'react';

import { Box, Text, Grid } from '@chakra-ui/core';

function Questions({ ...rest }) {
  const item = ({ title, description }) => (
    <Box>
      <Text
        fontSize={['lg', 'xl', 'xl']}
        display="flex"
        fontFamily="heading"
        as="h4"
        lineHeight={1.3}
        pb="2"
        color="gray.800"
      >
        {title}
      </Text>
      <Text
        fontSize={['sm', 'md', 'md']}
        display="flex"
        fontFamily="body"
        as="p"
        pt="2"
        lineHeight={1.8}
        color="gray.800"
      >
        {description}
      </Text>
    </Box>
  );
  return (
    <Box bg="white" p={['12', '12', '24']} mx={['12', '12', '24']} {...rest}>
      <Text
        fontSize={['2xl', '2xl', '3xl']}
        display="flex"
        fontFamily="heading"
        as="h3"
        lineHeight={1.3}
        pb="16"
        color="gray.800"
      >
        Your questions, answered.
      </Text>
      <Grid
        justifyContent="center"
        gridTemplateColumns={['auto', 'auto', 'auto', '1fr 1fr']}
        gridColumnGap="20"
        gridRowGap="12"
        mx="auto"
      >
        {item({
          title: 'How does the the scoring system works?',
          description:
            'If the high-rated player wins, then only a few rating points will be taken from the low-rated player. However, if the lower rated player scores an upset win, many rating points will be transferred. This ensures that now and then tears flow.',
        })}
        {item({
          title: 'What if someone leaves our slack workspace?',
          description:
            'Use the /change-elo @name hide command to hide someone from your workspaces leaderboards.',
        })}
        {item({
          title: 'Where can I get help if this app doesn’t work?',
          description:
            'Reach out to us on support@leaderboardapp.com and we will get back to you as soon as we can!',
        })}
        {item({
          title: 'Is this free?',
          description:
            'Your first month is free, no credit card required. This allows you to try it out without commiting. Plans start at $7.5/month for 8 users after your trial.',
        })}
        {item({
          title: 'Can we have multiple artboards?',
          description: 'In any channel use the /report or /report2vs2 to start a new leaderboard.',
        })}
        {item({
          title: 'Can I reset a leaderboard?',
          description:
            'Use the /reset command in the channel where you want to reset the leaderboard. This is only available for Slack admins.',
        })}
      </Grid>
    </Box>
  );
}

export default Questions;
