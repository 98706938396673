import React from 'react';
import { Text, Link, Box } from '@chakra-ui/core';

import Button from '../../components/Button';
import Page from '../../components/Page';
import Wrapper from '../../components/Wrapper';
import Footer from '../../components/Footer';

function Privacy() {
  const Title = ({ children }) => (
    <Text
      pb={2}
      fontSize="xl"
      marginLeft="auto"
      fontWeight="bold"
      color="gray.800"
      marginRight="auto"
      as="h2"
    >
      {children}
    </Text>
  );

  const Paragraph = ({ children }) => (
    <Text pb={4} fontSize="lg" color="gray.800" marginLeft="auto" marginRight="auto" as="p">
      {children}
    </Text>
  );
  return (
    <Page
      metaData={{
        title: 'Terms',
      }}
    >
      <Box position="fixed" right="8" top="8">
        <a href="mailto:support@leaderboardapp.com">
          <Button>Contact Us</Button>
        </a>
      </Box>
      <Wrapper px={4} pb="32" pt="32">
        <Text
          pb="16"
          fontSize="4xl"
          fontFamily="heading"
          marginLeft="auto"
          color="gray.800"
          marginRight="auto"
          as="h1"
        >
          Terms of Service
        </Text>
        <Title>1. Terms</Title>
        <Paragraph>
          By accessing the website at
          {' '}
          <Link href="https://leaderboardapp.com">leaderboardapp.com</Link>
, you are agreeing to be
          bound by these terms of service, all applicable laws and regulations, and agree that you
          are responsible for compliance with any applicable local laws. If you do not agree with
          any of these terms, you are prohibited from using or accessing this site. The materials
          contained in this website are protected by applicable copyright and trademark law.
        </Paragraph>
        <Title>2. Use License</Title>
        <Paragraph>
          <ol type="a">
            <li>
              Permission is granted to temporarily download one copy of the materials (information
              or software) on Leaderboard's website for personal, non-commercial transitory viewing
              only. This is the grant of a license, not a transfer of title, and under this license
              you may not:
              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any public display
                  (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software contained on Leaderboard's
                  website;
                </li>
                <li>remove any copyright or other proprietary notations from the materials; or</li>
                <li>
                  transfer the materials to another person or "mirror" the materials on any other
                  server.
                </li>
              </ol>
            </li>
            <li>
              This license shall automatically terminate if you violate any of these restrictions
              and may be terminated by Leaderboard at any time. Upon terminating your viewing of
              these materials or upon the termination of this license, you must destroy any
              downloaded materials in your possession whether in electronic or printed format.
            </li>
          </ol>
        </Paragraph>

        <Title>3. Disclaimer</Title>
        <Paragraph>
          <ol type="a">
            <li>
              The materials on Leaderboard's website are provided on an 'as is' basis. Leaderboard
              makes no warranties, expressed or implied, and hereby disclaims and negates all other
              warranties including, without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or non-infringement of intellectual
              property or other violation of rights.
            </li>
            <li>
              Further, Leaderboard does not warrant or make any representations concerning the
              accuracy, likely results, or reliability of the use of the materials on its website or
              otherwise relating to such materials or on any sites linked to this site.
            </li>
          </ol>
        </Paragraph>

        <Title>4. Limitations</Title>
        <Paragraph>
          In no event shall Leaderboard or its suppliers be liable for any damages (including,
          without limitation, damages for loss of data or profit, or due to business interruption)
          arising out of the use or inability to use the materials on Leaderboard's website, even if
          Leaderboard or a Leaderboard authorized representative has been notified orally or in
          writing of the possibility of such damage. Because some jurisdictions do not allow
          limitations on implied warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </Paragraph>

        <Title>5. Accuracy of materials</Title>
        <Paragraph>
          The materials appearing on Leaderboard's website could include technical, typographical,
          or photographic errors. Leaderboard does not warrant that any of the materials on its
          website are accurate, complete or current. Leaderboard may make changes to the materials
          contained on its website at any time without notice. However Leaderboard does not make any
          commitment to update the materials.
        </Paragraph>

        <Title>6. Links</Title>
        <Paragraph>
          Leaderboard has not reviewed all of the sites linked to its website and is not responsible
          for the contents of any such linked site. The inclusion of any link does not imply
          endorsement by Leaderboard of the site. Use of any such linked website is at the user's
          own risk.
        </Paragraph>

        <Title>7. Modifications</Title>
        <Paragraph>
          Leaderboard may revise these terms of service for its website at any time without notice.
          By using this website you are agreeing to be bound by the then current version of these
          terms of service.
        </Paragraph>

        <Title>8. Governing Law</Title>
        <Paragraph>
          These terms and conditions are governed by and construed in accordance with the laws of
          London and you irrevocably submit to the exclusive jurisdiction of the courts in that
          State or location.
        </Paragraph>
      </Wrapper>
      <Footer />
    </Page>
  );
}

export default Privacy;
