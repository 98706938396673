import React from 'react';

import { Link as ClassicLink } from '@chakra-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function Link(props) {
  const { to, children } = props;
  const isExternal = /^https?:\/\//.test(to);
  const isEmailAddress = /^mailto:/.test(to);

  if (isExternal || !to) {
    return (
      <ClassicLink href={to} target="_blank">
        {children}
      </ClassicLink>
    );
  }
  if (isEmailAddress) {
    return (
      <ClassicLink href={to} target="_blank">
        {children}
      </ClassicLink>
    );
  }
  return <RouterLink to={to}>{children}</RouterLink>;
}
