import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@chakra-ui/core';

function Page({ metaData, children, bg = 'brand.beige' }) {
  const defaultTitle = 'Leaderboard 🏅🏆 - A Slack app for tracking your office games.';
  return (
    <Box bg={bg} minHeight="100vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {metaData && metaData.title ? `${metaData.title} - Leaderboard` : defaultTitle}
        </title>
      </Helmet>
      {children}
    </Box>
  );
}

export default Page;
