import React from 'react';
import { Text, Link, Box } from '@chakra-ui/core';

import Page from '../../components/Page';
import Wrapper from '../../components/Wrapper';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

function Privacy() {
  const Paragraph = ({ children }) => (
    <Text pb={4} fontSize="lg" color="gray.800" marginLeft="auto" marginRight="auto" as="p">
      {children}
    </Text>
  );
  return (
    <Page
      metaData={{
        title: 'Support',
      }}
    >
      <Box position="fixed" right="8" top="8">
        <a href="mailto:support@leaderboardapp.com">
          <Button>Contact Us</Button>
        </a>
      </Box>
      <Wrapper px={4} pb="32" pt="32">
        <Text
          pb="16"
          fontSize="4xl"
          fontFamily="heading"
          marginLeft="auto"
          color="gray.800"
          marginRight="auto"
          as="h1"
        >
          Questions or need help?
        </Text>
        <Paragraph>
          Get in touch
          {' '}
          <Link href="mailto:support@leaderboardapp.com">support@leaderboardapp.com</Link>
        </Paragraph>
      </Wrapper>
      <Footer />
    </Page>
  );
}

export default Privacy;
