import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { useAuth } from './context/auth';
import { fetchTeam } from './api';

function Login({ location }) {
  const { setAuthToken } = useAuth();
  const [tokenIsValid, setTokenIsValid] = useState(null);
  const parsed = queryString.parse(location.search);
  const token = decodeURI(parsed.token);
  useEffect(() => {
    fetchTeam({ token })
      .then((resp) => resp.json())
      .then(() => {
        setAuthToken(token);
        setTokenIsValid(true);
      })
      .catch(() => setTokenIsValid(false));
  }, [token, setAuthToken]);

  if (tokenIsValid === true) {
    const next = parsed.next ? parsed.next : '/settings';
    return <Redirect to={next} />;
  }

  if (tokenIsValid === false) {
    return <div>Invalid token</div>;
  }

  return <div>Loading</div>;
}

export default withRouter(Login);
