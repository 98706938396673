import styled from "styled-components";

import { layout, position, space, borderRadius } from "styled-system";

const Img = styled.img`
  display: block;

  ${layout};
  ${position};
  ${space};
  ${borderRadius};
`;

export default Img;
