import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';

import { apiUrl } from '../../environment';

import Page from '../../components/Page';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import office from './images/office.jpg';

import girl from './images/leaderboard-girl.png';
import girl2x from './images/leaderboard-girl2x.png';

import logo from './images/logo.png';
import logo2x from './images/logo2x.png';

import screenshotLeaderboard from './images/screenshot-leaderboard.png';
import screenshotLeaderboard2x from './images/screenshot-leaderboard2x.png';

import screenshotReport from './images/screenshot-report.png';
import screenshotReport2x from './images/screenshot-report2x.png';

import screenshotWhoNext from './images/screenshot-whonext.png';
import screenshotWhoNext2x from './images/screenshot-whonext2x.png';

import buttonOutline2x from './images/button-outline2x.png';

import CommandBlock from './CommandBlock';
import Questions from './Questions';

function Home() {
  return (
    <Page>
      <Box position="fixed" right="8" top="8">
        <a href="mailto:support@leaderboardapp.com">
          <Button>Contact Us</Button>
        </a>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" py="16" px="16">
        <Box mb="6">
          <Image
            src={logo}
            srcSet={`${logo} 1x,${logo2x} 2x`}
            width={[170, 170, 170, 211]}
            height="auto"
          />
        </Box>
        <Image
          src={girl}
          srcSet={`${girl} 1x,${girl2x} 2x`}
          width={[320, 320, 320, 439]}
          height="auto"
        />
        <Text
          fontSize={['2xl', '3xl', '4xl']}
          display="flex"
          fontFamily="heading"
          textAlign="center"
          as="h1"
          lineHeight={1.3}
          pt="6"
          pb="2"
          color="gray.800"
          maxWidth={550}
        >
          Keep track of your office games
        </Text>
        <Text
          fontSize={['sm', 'md', 'lg']}
          display="flex"
          fontFamily="body"
          textAlign="center"
          as="p"
          lineHeight={1.8}
          color="gray.700"
          maxWidth={550}
        >
          Challenge your co-workers for a game of Mario Kart, Ping Pong, Table Football… And when
          you’re finished playing, report your score. Ready? set, go!
        </Text>

        <Flex
          width={231}
          height={31}
          backgroundImage={`url('${buttonOutline2x}')`}
          backgroundSize="231px 31px"
          justifyContent="center"
          alignItems="center"
          mt="6"
        >
          <a href={`${apiUrl}slack/signup`}>
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </Flex>
      </Box>

      <Box py="8" px={['12', '12', '24']}>
        <img alt="office" height="auto" width="100%" src={office} />
      </Box>

      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        py="16"
        p={['12', '12', '24']}
      >
        <Text
          fontSize={['1xl', '2xl', '3xl']}
          display="flex"
          fontFamily="heading"
          textAlign="center"
          as="h2"
          lineHeight={1.3}
          pt="6"
          pb="2"
          color="gray.800"
          maxWidth={550}
        >
          Multiple commands to fuel the fun
        </Text>
        <Text
          fontSize={['sm', 'md', 'lg']}
          display="flex"
          fontFamily="body"
          textAlign="center"
          as="p"
          lineHeight={1.8}
          color="gray.700"
          maxWidth={550}
          mb="8"
        >
          Challenge your co-workers for a game of Mario Kart, Ping Pong, Table Football… And when
          you’re finished playing, report your score. Ready? set, go!
        </Text>

        <CommandBlock
          src={screenshotLeaderboard}
          src2x={screenshotLeaderboard2x}
          title="/leaderboard"
          srcMaxWidth={398}
          description={(
            <>
              {' '}
              Slash Leaderboard, followed by enter
              {' '}
              <span role="img" aria-label="drums">
                🥁
              </span>
              . And here it is… everyone’s position on the leaderboard together with their ELO
              rating.
            </>
          )}
        />

        <CommandBlock
          src={screenshotReport}
          src2x={screenshotReport2x}
          srcMaxWidth={398}
          color="brand.blue"
          title="/report"
          reverse
          description={(
            <>
              Finished playing a game of Mario Kart, Ping Pong, FIFA….? Time to report your game.
              Use the /report command and select if you won, lost or drew. Eeeeeeeh voila!
              {' '}
              <span role="img" aria-label="sport flag">
                🏁
              </span>
            </>
          )}
        />

        <CommandBlock
          src={screenshotWhoNext}
          src2x={screenshotWhoNext2x}
          srcMaxWidth={398}
          title="/who-next"
          color="brand.pink"
          description={(
            <>
              Don’t keep your
              {' '}
              <span role="img" aria-label="heart">
                ❤️
              </span>
              {' '}
              love
              {' '}
              <span role="img" aria-label="heart">
                ❤️
              </span>
              {' '}
              for only those sitting next to you in the office. Figure out who you didn’t play
              enough.
            </>
          )}
        />
      </Flex>
      <Questions />
      <Footer />
    </Page>
  );
}

export default Home;
