import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { AuthContext } from './context/auth';

import Login from './Login';
import Logout from './Logout';
import PrivateRoute from './PrivateRoute';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Support from './pages/Support';
import Privacy from './pages/Privacy';
import Manage from './pages/Manage';
import Success from './pages/Success';

import { usePersistedState } from './utils/usePersistedState';

function App() {
  const tokenKey = 'token';
  const [authToken, setAuthToken] = usePersistedState(null, tokenKey);

  const setToken = (token) => {
    setAuthToken(token);
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/success" component={Success} />
        <PrivateRoute exact path="/settings" component={Manage} />
      </Switch>
    </AuthContext.Provider>
  );
}

export default withRouter(App);
