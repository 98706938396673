import React from 'react';

import Page from '../../components/Page';
import Footer from '../../components/Footer';

import Subscription from './Subscription';
import Header from './Header';

const ManagePage = () => (
  <Page metaData={{ title: 'Manage your subscription' }}>
    <Header />
    <Subscription />
    <Footer />
  </Page>
);

export default ManagePage;
