import React from 'react';
import { Text, Flex } from '@chakra-ui/core';

import Image from '../../components/Image';

function CommandBlock({
  src,
  src2x,
  srcMaxWidth,
  title,
  description,
  color = 'brand.yellow',
  reverse = false,
}) {
  const flexDirection = reverse ? 'row-reverse' : 'row';
  return (
    <Flex
      flexDirection={['column-reverse', 'column-reverse', flexDirection]}
      justifyContent="center"
      alignItems="center"
      width="100%"
      py="8"
    >
      <Flex
        flexDirection="column"
        width={['100%', '100%', '50%']}
        pr={!reverse && [0, 0, '24']}
        pl={reverse && [0, 0, '24']}
      >
        <Text
          fontSize={['xl', '1xl', '2xl']}
          display="flex"
          fontFamily="heading"
          as="h3"
          lineHeight={1.3}
          pt="6"
          pb="2"
          color="gray.800"
          maxWidth={550}
        >
          {title}
        </Text>
        <Text
          fontSize={['sm', 'md', 'lg']}
          fontFamily="body"
          as="p"
          lineHeight={1.8}
          color="gray.700"
          maxWidth={550}
          mb="8"
        >
          {description}
        </Text>
      </Flex>
      <Flex
        bg={color}
        width={['100%', '100%', '50%']}
        p="10"
        justifyContent="center"
        alignItems="center"
        height={379}
      >
        <Image
          src={src}
          srcSet={`${src} 1x,${src2x} 2x`}
          maxWidth={srcMaxWidth}
          width="100%"
          height="auto"
        />
      </Flex>
    </Flex>
  );
}

export default CommandBlock;
