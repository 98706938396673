import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { useAuth } from './context/auth';

function Logout({ match, location }) {
  const { setAuthToken } = useAuth();

  setAuthToken(null);

  return <Redirect to="/" />;
}

export default withRouter(Logout);
