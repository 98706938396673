import React from 'react';
import { Flex, Text } from '@chakra-ui/core';

import Wrapper from '../Wrapper';
import RouterLink from '../RouterLink';

function BigText() {
  return (
    <Wrapper px={4} py={16}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" alignSelf="center">
        <Text fontSize="sm" fontFamily="heading" textAlign="center" color="gray.800">
          Or read our boring legals
        </Text>
        <Text fontSize="sm" fontFamily="heading" textAlign="center" color="gray.800">
          <RouterLink to="/privacy">Privacy</RouterLink>
          &nbsp;&&nbsp;
          <RouterLink to="/terms">Terms</RouterLink>
        </Text>
      </Flex>
    </Wrapper>
  );
}

export default BigText;
