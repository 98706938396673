import React from 'react';
import { Text, Link, Box } from '@chakra-ui/core';

import Page from '../../components/Page';
import Wrapper from '../../components/Wrapper';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

function Privacy() {
  const Paragraph = ({ children }) => (
    <Text pb={4} fontSize="lg" color="gray.800" marginLeft="auto" marginRight="auto" as="p">
      {children}
    </Text>
  );
  return (
    <Page
      metaData={{
        title: 'Privacy',
      }}
    >
      <Box position="fixed" right="8" top="8">
        <a href="mailto:support@leaderboardapp.com">
          <Button>Contact Us</Button>
        </a>
      </Box>
      <Wrapper px={4} pb="32" pt="32">
        <Text
          pb="16"
          fontSize="4xl"
          fontFamily="heading"
          marginLeft="auto"
          color="gray.800"
          marginRight="auto"
          as="h1"
        >
          Privacy
        </Text>
        <Paragraph>
          Your privacy is important to us. It is Leaderboard's policy to respect your privacy
          regarding any information we may collect from you across our website,
          {' '}
          <Link href="https://leaderboardapp.com">leaderboardapp.com</Link>
, and other sites we own
          and operate.
        </Paragraph>

        <Paragraph>
          We only ask for personal information when we truly need it to provide a service to you. We
          collect it by fair and lawful means, with your knowledge and consent. We also let you know
          why we’re collecting it and how it will be used.
        </Paragraph>
        <Paragraph>
          We only retain collected information for as long as necessary to provide you with your
          requested service. What data we store, we’ll protect within commercially acceptable means
          to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or
          modification.
        </Paragraph>
        <Paragraph>
          We don’t share any personally identifying information publicly or with third-parties,
          except when required to by law.
        </Paragraph>
        <Paragraph>
          Our website may link to external sites that are not operated by us. Please be aware that
          we have no control over the content and practices of these sites, and cannot accept
          responsibility or liability for their respective privacy policies.
        </Paragraph>
        <Paragraph>
          You are free to refuse any request we may make for your personal information, with the
          understanding that we may be unable to provide you with some of your desired services.
          Please contact us if you would like your personal information removed.
        </Paragraph>
        <Paragraph>
          Your continued use of our website will be regarded as acceptance of our practices around
          privacy and personal information. If you have any questions about how we handle user data
          and personal information, feel free to contact us.
        </Paragraph>
        <Paragraph>
          If you have any questions about this Privacy Policy, please contact us by email:
          {' '}
          <Link href="mailto:hello@hifiv.es">support@leaderboardapp.com</Link>
        </Paragraph>

        <Paragraph>This policy is effective as of 4 July 2019.</Paragraph>
      </Wrapper>
      <Footer />
    </Page>
  );
}

export default Privacy;
