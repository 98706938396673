import React from 'react';

import { PseudoBox } from '@chakra-ui/core';

function Button({ children, width, onClick }) {
  return (
    <PseudoBox
      as="button"
      height={42}
      lineHeight="none"
      width={width}
      px="6"
      fontWeight={700}
      fontSize={16}
      fontFamily="heading"
      borderRadius={21}
      onClick={onClick}
      bg="white"
      _hover={{ bg: 'pink.50' }}
    >
      {children}
    </PseudoBox>
  );
}

export default Button;
