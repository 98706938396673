import { apiUrl } from './environment';

function headers({ token = null }) {
  const h = {
    'Content-Type': 'application/json',
  };

  if (token !== null) {
    h.Authorization = `Bearer ${token}`;
  }
  return h;
}

export function stripeCreateSession({
  planId, successUrl, cancelUrl, token,
}) {
  return fetch(`${apiUrl}stripe/create-session`, {
    method: 'POST',
    headers: headers({ token }),
    body: JSON.stringify({
      plan_id: planId,
      success_url: successUrl,
      cancel_url: cancelUrl,
    }),
  });
}

export function cancelAllSubscriptions({ token }) {
  return fetch(`${apiUrl}stripe/cancel-subscriptions`, {
    method: 'DELETE',
    headers: headers({ token }),
  });
}

export function fetchTeam({ token }) {
  return fetch(`${apiUrl}team`, {
    method: 'GET',
    headers: headers({ token }),
  });
}

export function fetchPlans() {
  return fetch(`${apiUrl}stripe/available-plans`, {
    method: 'GET',
    headers: headers({}),
  });
}
